import { Component, OnInit } from '@angular/core';
import { ContentsService } from '../../services/contents.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-details-page',
  templateUrl: './blog-details-page.component.html',
  styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {

  constructor(private contentsService:ContentsService,private router:ActivatedRoute) { }
  path = environment.serverUrl;
  getnewsModel!:any;
  newsModel!:any;


  ngOnInit(): void {
    this.router.params.subscribe(x => {
      this.getnewslist(x['id']);
          })
  }


  getnewslist(id:any){
    this.contentsService.getid(id).subscribe((data:any)=> {
      this.getnewsModel = data;
      // console.log("OK",this.getnewsModel)
    })

  }
}
