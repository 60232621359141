<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Instructors</li>
            </ul>
            <h2>Team of Instructors</h2>
        </div>
    </div>
</div>

<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of ourteamModel ;let i = index">
                <div class="single-instructor-member mb-30">
                    <div class="member-image">
                        <img src="assets/img/instructor/instructor1.jpg" alt="images">
                        <!-- <img src="{{path}}/WebImages/Teams/{{item.photo}}" alt="Image"> -->
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/single-instructor">{{item.username}}</a></h3>
                        <span>{{item.categoryName}}</span>
                        <ul class="social">
                            <li><a href="{{item.facebook}}" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="{{item.twitter}}" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="{{item.instagram}}" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->