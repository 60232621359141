<!-- <section class="row text-center">
    <owl-carousel-o [options]="recommendedCarOptions" >
                <ng-container *ngFor="let data of slidersModel">
                  <ng-template carouselSlide>
            <div class="rental-car-item">
                <div class="listing-item mb-0">										
                    <div class="listing-img">
                        <a routerLink="{{data.link}}">
                            <img img [src]="path+'/WebImages/Announcements/'+data.image" alt="Image">
                        </a>
                    </div>									
                </div>
            </div>
        </ng-template>
    </ng-container>
  </owl-carousel-o>
</section> -->


<div class="gym-home-slides">
    <owl-carousel-o [options]="gymHomeSlides" >
        <ng-template carouselSlide *ngFor="let data of slidersModel ; let i = index">
            <img img [src]="path+'/WebImages/Announcements/'+data.image" alt="Image">
            <!-- <div [ngClass]="'bg-' + (i + 1)" [ngStyle]="{ 'background-image': 'url(' + photoUrl + slide.frontAnnouncementRecords[0]?.fileUrl + ')' }"> -->
                
                <div class="container">
                    <div class="gym-banner-content">

                    </div>
                </div>
            <!-- </div> -->
        </ng-template>

    </owl-carousel-o>
</div>
