<header class="header-area p-relative">
    <div class="top-header top-header-style-four">
        
        <!-- <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            Telefon: 
                            <a href="tel:+502464674">+502 464 674</a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Bizi takip edin:</span>
                        <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <ul class="top-header-login-register">
                        <a style="background-color: #000;border-color: #000;" routerLink="/checkout" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">FİYAT TEKLİFİ AL</span><i class="bx bx-book-reader icon-arrow after"></i></a>

                    </ul>
                </div>
            </div>
        </div> -->
    </div>
    <div class="navbar-area navbar-style-three" [ngClass]="{'sticky': isSticky}">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img [src]="logoPath" (window:scroll)="onScroll()" style="height: 140px;
                    position: absolute;
                    margin-top: -42px;
                    margin-left: -7%;" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span> 
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item" *ngFor="let item of frontModel">

                            <a routerLink="{{item.link}}"    class="nav-link"
                                *ngIf="item.inverseParent.length == 0  && item.link">{{item.title}}</a>
    
                            <a class="nav-link dropdown-toggle"  id="{{item.pageId}}"  href="{{item.slug}}"
                                *ngIf="item.inverseParent.length > 0&& item.pageId">{{item.title}}</a>
    
                            <a class="nav-link dropdown-toggle"  href="{{item.link}}"
                                *ngIf="item.inverseParent.length > 0 ">{{item.title}}</a>
    
                            <ul class="dropdown-menu" *ngIf="item.inverseParent.length > 0">
                                <li class="nav-item" *ngFor="let item2 of item.inverseParent">
                                    <a *ngIf="item2.pageId" id="{{item2.pageId}}"   href="services/{{item2.pageId}}/{{item2.slug}}"
                                        class="nav-link">{{item2.title}}</a>
                                    <a *ngIf="!item2.pageId" href="{{item2.link}}" class="nav-link">{{item2.title}}</a>
                                </li>
                            </ul>
                        </li>
                <div class="col-lg-4" style="margin: auto;">
                    <div class="top-header-btn">
                        <a routerLink="/priceOffer" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Fiyat Teklifi Al</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                    </div>
                </div>

                    </ul>
                </div>
    
            </nav>
        </div>
    </div> 
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>