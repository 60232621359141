<section class="distance-learning-area bg-e4feff pt-100" style="padding-top: 0px;"> 
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="distance-learning-image" style="margin-top: -55px;">
                    <img src="assets/img/oven.jpg" alt="image" style="border-radius: 25px;">
                </div>
            </div>
            <br>
            <div class="col-lg-6 col-md-12">
                <div class="distance-learning-content">
                    <span class="sub-title">Tadİvo</span>
                    <h2>TADİVO Fırın</h2>
                    <p style="text-align: justify;">TADİVO geleneksel fırıncılık sanatını modern teknolojiyle birleştirerek, her bir ürünümüzde kalite ve lezzeti ön planda tutar. Usta fırıncılarımızın elinden çıkan ekmeklerimiz, özenle seçilmiş malzemelerle hazırlanan pastalarımız ve enfes tatlılarımızla damaklarınızı şenlendirmek için sabırsızlanıyoruz.​ </p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-self-growth"></i> Lezzet Dolu Menü</span></li>
                        <li><span><i class="flaticon-clock"></i>Sunulan Menü</span></li>
                        <li><span><i class="flaticon-ebook"></i>Müşteri Memnuniyet Oranı</span></li>
                    </ul>
                    <!-- <a routerLink="/register" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Join For Free</span><i class="bx bx-user-circle icon-arrow after"></i></a> -->
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape4"><img src="assets/img/business-coaching/business-shape4.png" alt="image"></div>
</section>