<app-header-style-six></app-header-style-six>

<app-homeeight-main-banner></app-homeeight-main-banner>
<app-services></app-services>

<!-- <div class="advert"><p>Kataloğ</p></div> -->
<div class="reklams">
    <div class="yazi"><a target="_blank" href="assets/img/pdf/tadivo-katalog.pdf">
            <p style="transform: rotate(180deg);
        margin-right: 10px;font-size: 17px;color: #fff;">Katalog</p>
        </a></div>

    <!-- <div class="yazi2">728x90</div> -->
</div>
<div class="reklams" style="margin-top: 90px;" *ngFor="let data of categoryModel ; let i = index">
    <div class="yazi"><a target="_blank" [href]="path+'Monthlymenu/'+data.image">
            <p style="transform: rotate(180deg);
        margin-right: 10px;font-size: 17px;color:#fff;">Aylık Menü</p>
        </a></div>
        

    <!-- <div class="yazi2">728x90</div> -->
</div>
<app-homeeight-about></app-homeeight-about>
<br>
<br>
<app-trending-categories></app-trending-categories>
<!-- <div class="testimonials-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Sizden Gelenler</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div> -->

<!-- <app-homeeight-courses></app-homeeight-courses> -->

<!-- <app-funfacts-feedback></app-funfacts-feedback> -->

<section class="faq-area  pb-100">
    <app-faq></app-faq>
    <div class="divider"></div>
</section>

<!-- <app-homeeight-events></app-homeeight-events> -->

<div class="blog-area pt-100 pb-70" style="padding-top: 1px;padding-bottom: 1px;">
    <div class="container">
        <div class="section-title text-start">
            <h2>Tadivo Blog</h2>
            <a routerLink="/blog" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span
                    class="label">Tüm Bloglar</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div>
<!-- <div class="partner-area bg-color ptb-100">
    <div class="container">
        <app-partner-style-one></app-partner-style-one>
    </div>
</div> -->
<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="section-title text-start">
                <!-- <span class="sub-title">Explore News</span> -->
                <h2>Tadivo Galeri</h2>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let data of _album; let i=index">

                <div class="single-gallery-item mb-30">
                    <img img [src]="data.thumb" (click)="open(i)" alt="Image">

                </div>
            </div>
        </div>
    </div>
    <!-- </div><app-premium-access></app-premium-access> -->