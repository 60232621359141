import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ContentsModel } from 'src/app/components/models/contentsModel';
import { ContentsService } from 'src/app/components/services/contents.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homeeight-main-banner',
  templateUrl: './homeeight-main-banner.component.html',
  styleUrls: ['./homeeight-main-banner.component.scss']
})
export class HomeeightMainBannerComponent implements OnInit {

  path = environment.serverUrl;
  slidersModel!: ContentsModel;
  constructor(private contentsService: ContentsService) { }

  ngOnInit(): void {
    this.getnewsList();

  }
  getnewsList() {
    this.contentsService.getsldiderList().subscribe(newsdata => {
      this.slidersModel = newsdata;
    })
  }
  

  recommendedCarOptions: OwlOptions = {
    margin: 24,
    loop: true,
		nav: false,
		dots: true,
    smartSpeed: 2000,
    autoHeight: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		],
    autoplaySpeed: 2000,

    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
  };


  homeSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
    responsive:{
      0: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1170: {
        items: 1,
      },
    },
		items: 1,
		autoHeight: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		]
    }

    gymHomeSlides: OwlOptions = {
      autoplayHoverPause: true,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      autoHeight: true,
      autoplay: true,
      dots: false,
      loop: true,
      nav: true,
      items: 1,
      navText: [
          "<i class='flaticon-arrows'></i>",
          "<i class='flaticon-right-arrow'></i>"
      ]
  }
}
