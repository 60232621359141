import { Component, OnInit, HostListener } from '@angular/core';
import { FrontMenüService } from '../../services/frontMenü.service';

@Component({
    selector: 'app-header-style-six',
    templateUrl: './header-style-six.component.html',
    styleUrls: ['./header-style-six.component.scss']
})
export class HeaderStyleSixComponent implements OnInit {

    frontModel!: any;
    logoPath: string = 'assets/img/logo/tadivo.svg'; // varsayılan logo yolu

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const pageHeight = document.body.scrollHeight;
        const scrollPercentage = scrollPosition / (pageHeight - windowHeight);
    
        // Yüzde 50 ile 70 arasındaysa ikinci logo göster
        if (scrollPercentage >= 0.34 && scrollPercentage <= 0.5) {
          this.logoPath = 'assets/img/logo/tadivo-firin.svg';
        } else {
          this.logoPath = 'assets/img/logo/tadivo.svg';
        }
    }


    // onScroll() {
    //   const scrollPosition = window.scrollY;
    //   const windowHeight = window.innerHeight;
    //   const pageHeight = document.body.scrollHeight;
    //   const scrollPercentage = scrollPosition / (pageHeight - windowHeight);
  
    //   // Yüzde 40 ile 60 arasındaysa ilk logo göster
    //   if (scrollPercentage >= 0.4 && scrollPercentage < 0.6) {
    //     this.logoPath = 'assets/img/logo/tadivo.svg';
    //   }
    //   // Yüzde 60 ile 80 arasındaysa ikinci logo göster
    //   else if (scrollPercentage >= 0.6 && scrollPercentage < 0.8) {
    //     this.logoPath = 'assets/logo/tadivo-firin1.svg';
    //   }
    //   // Yüzde 80'den büyükse üçüncü logo göster
    //   else if (scrollPercentage >= 0.8) {
    //     this.logoPath = 'assets/img/logo/tadivo-firin.svg';
    //   }
    //   // Diğer durumlarda varsayılan logo kullan
    //   else {
    //     this.logoPath = 'assets/img/logo/tadivo.svg';
    //   }
    // }

    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private frontMenüService: FrontMenüService) {
        
     }

    ngOnInit(): void {
        this.frontist();
    }

    frontist() {
        this.frontMenüService.getList().subscribe(data => {
            this.frontModel = data;
            // console.log("Menüler",this.frontModel)
        })
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

     navMethod: boolean = false;
  toggleNav() {
    this.navMethod = !this.navMethod;
  }
  // Search
  searchMethod: boolean = false;
  toggleSearch() {
    this.searchMethod = !this.searchMethod;
  }
  // Mobile 
  open: boolean = false;
  trigger(item: { open: boolean; }) {
    item.open = !item.open;
  }
  // Add class on resize and onload window
  visible: boolean = false;
  breakpoint: number = 991;
  public innerWidth: any;
  detectHeader() {
    this.innerWidth = window.innerWidth;
    this.visible = this.innerWidth >= this.breakpoint;
  }

}