<app-header-style-seven></app-header-style-seven>

<div class="page-title-area  item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About</li>
            </ul> -->
            <h2>Fiyat Teklif Al</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content left-content">
                    <!-- <span class="sub-title">About Us</span> -->
                    <h2>Tadivo Catering</h2>
                    <!-- <h6>We can support student forum 24/7 for national and international students.</h6> -->
                    <p>Müşterilerimize güzel bir hizmet oluşturmak adına bizimle bu yola çıktığınız ve işbirliğiniz için çok teşekkür ederiz. İşyerleri, fabrikalar, okullar, şantiyeler ve kamplarda ki personel için gerekli her yerde sözleşme bazında toplu yemek hizmetlerini en iyi şekilde sunarız. Tüm mutfak faaliyetlerinde tüm yükümlüğü üzerine alır, mutfak giderlerinin bütçelenmesi, personel alımı ve eğitimine ve donanımına gıda malzemelerinin seçimine yemeklerin hazırlanmasına günlük kalite ve temizlik standartlarına,ve kontrolüne kadar takip eder. Uygularız….
                    </p>
                    <!-- <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>Education encompasses both the teaching and learning of knowledge, proper conduct, and technical competency.</p>
                    </div> -->
                    <!-- <div class="signature">
                        <img src="assets/img/signature.png" alt="image">
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-right-image">
                    <img src="assets/img/about/tadivo-2.jpg" alt="image">
                    <img src="assets/img/about/tadivo-1.jpg" alt="image">
                    <div class="text-box">
                        <div class="inner">
                            Toplam Talep
                            <span>75K+</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>100,000 online courses</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Expert instruction</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Creating. Results.</li>
                            <li><i class='bx bx-check'></i> Expect more</li>
                            <li><i class='bx bx-check'></i> Good thinking</li>
                            <li><i class='bx bx-check'></i> In real we trust</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Lifetime access</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Stay real. Always.</li>
                            <li><i class='bx bx-check'></i> We have you covered</li>
                            <li><i class='bx bx-check'></i> We turn heads</li>
                            <li><i class='bx bx-check'></i> Your brand, promoted</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Contact Us</span> -->
            <h2>Hizmet Talep Formu</h2>
        </div>
        <div class="contact-form">
            <form id="contactForm" [formGroup]="form"  (ngSubmit)="submitForm()">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" name="companyName" formControlName="companyName" id="companyName" type="text"  class="form-control" placeholder="Firma Adı">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" name="authorizedNameSurname" formControlName="authorizedNameSurname" id="authorizedNameSurname"class="form-control"  placeholder="Yetkili Adı Soyadı">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required name="phone" id="phone" formControlName="phone" type="number" class="form-control" placeholder="Telefon Numarası">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required id="email" name="email" formControlName="email" type="text"  class="form-control" placeholder="E-Posta Adresi">
                        </div>
                    </div>
                   
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required name="numberofPeople" formControlName="numberofPeople" id="numberofPeople" class="form-control" type="number"  placeholder="Kişi Sayısı">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required name="region" formControlName="region" id="region" class="form-control"   placeholder="Bölge">
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required name="message" id="message" formControlName="message" cols="30" rows="5" class="form-control" placeholder="Mesajınız..."></textarea>
                        </div>
                    </div> -->
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" ><i class='bx bx-paper-plane icon-arrow before'></i><span class="label"> Gönder</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div>
<!-- <div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div> -->

<!-- <div class="funfacts-style-two ptb-70">
    <div class="container">
        <app-funfacts></app-funfacts>
    </div>
</div> -->

<!-- <div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div> -->

<!-- <div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-one></app-instructors-style-one>
    </div>
</div> -->

<!-- <div class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</div>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner-style-two></app-partner-style-two>
    </div>
</div> -->

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->