import { Component, OnInit } from '@angular/core';
import { PriceOfferService } from '../../services/priceOffer.service';
import { PriceOfferForm } from '../../models/priceOfferForm';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-about-page-two',
  templateUrl: './about-page-two.component.html',
  styleUrls: ['./about-page-two.component.scss']
})
export class AboutPageTwoComponent implements OnInit {
  form!: FormGroup;

  constructor(private priceOfferService:PriceOfferService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.priceOfferForm();
  }


  priceOfferForm() {
    this.form = this.formBuilder.group({
      companyName: ['', Validators.required],
      authorizedNameSurname: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      numberofPeople: ['', Validators.required],
      region: ['', Validators.required],
      isConfirm:true

    })
  }
  submitForm() {
    // console.log("İlk",this.form)
    if (this.form.valid) {
      const priceOfferForm: PriceOfferForm = Object.assign(this.form.value);
      Swal.fire({
        title: 'Talebiniz gönderilsin mi?',
        showDenyButton: true,
        confirmButtonText: 'Evet', confirmButtonColor: "#238dc1",
        denyButtonText: `Hayır`, denyButtonColor: "#b47f00",
      }).then((response) => {
        if (response.isConfirmed) {
          debugger;

          this.priceOfferService.priceSend(priceOfferForm).subscribe((data: any) => {
            if (data) {
              // console.log("Gitti",response)

              Swal.fire({
                title: 'Başarılı',
                text: 'Fiyat talebiniz alınmıştır...',
                icon: 'success', iconColor: "#89dc65",
                confirmButtonText: 'Tamam', confirmButtonColor: "#89dc65",
              }).then(function (result) {
                if (result.value) {
                  window.location.href = 'priceOffer';
                }
              })
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: 'İletişim bilgilerini doldurun',
        icon: 'warning', iconColor: "#d4c201",
        confirmButtonText: 'Tamam', confirmButtonColor: "#89dc65",
      })
    }
  }

}
