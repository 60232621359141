<app-header-style-seven></app-header-style-seven>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li>Gallery</li>
            </ul> -->
            <h2>Galeri</h2>
        </div>
    </div>
</div>

<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let data of _album; let i=index">
                
                <div class="single-gallery-item mb-30">
                    <img img [src]="data.thumb"(click)="open(i)" alt="Image">

                </div>
            </div>
        </div>
    </div>
</div>