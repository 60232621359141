<div class="motivation-categories-area pt-100 pb-70" style="padding-bottom: 0px;"> 
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">COURSES CATEGORIES</span> -->
            <h2>Her Tabakta Sağlık, Her Lokmadan Mutluluk 
            </h2>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-3 col-md-6">
                <div class="motivation-categories-card mb-30">
                    <ul class="motivation-categories-list">
                        <li>
                            <div class="icon">
                                <img src="assets/img/icon/özel-ihtiyac.svg" alt="image" style="height: 75px;margin-left: -15px;"> 

                                <!-- <i class="bx bx-shape-triangle"></i> -->
                            </div>
                            <h3>
                                <a routerLink="/">Özel İhtiyaca Yönelik Menüler
                                </a>
                            </h3>
                            <!-- <span>12 Courses</span> -->
                        </li>
                        <li>
                            <div class="icon">
                                <img src="assets/img/icon/taze-ve.svg" alt="image" style="height: 75px;margin-left: -15px;">

                                <!-- <i class="bx bx-first-aid"></i> -->
                            </div>
                            <h3>
                                <a routerLink="/">Taze ve Kaliteli Malzemeler
                                </a>
                            </h3>
                            <!-- <span>6 Courses</span> -->
                        </li>
                        <li>
                            <div class="icon">
                                <img src="assets/img/icon/sağlikli-tarifler.svg" alt="image" style="height: 75px;margin-left: -15px;">

                                <!-- <i class="bx bx-book-reader"></i> -->
                            </div>
                            <h3>
                                <a routerLink="/">Sağlıklı Tarifler ve Beslenme Önerileri
                                </a>
                            </h3>
                            <!-- <span>4 Courses</span> -->
                        </li>
                        <!-- <li>
                            <div class="icon">
                                <i class="bx bx-font-family"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Tasarruf.
                                </a>
                            </h3>
                            <span>9 Courses</span>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="motivation-categories-card mb-30">
                    <div class="motivation-categories-image">
                        <img src="assets/img/icon/tadivo-diyet.png" alt="image">
                        <!-- <span>1500+ Courses Availabel <a href="courses-2-columns-style-1.html">View all Courses</a></span> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="motivation-categories-card mb-30">
                    <ul class="motivation-categories-list">
                        <!-- <li>
                            <div class="icon">
                                <i class="bx bxs-drink"></i>
                            </div>
                            <h3>
                                <a routerLink="/courses-3-columns-style-1">Science</a>
                            </h3>
                            <span>5 Courses</span>
                        </li> -->
                        <li>
                            <div class="icon">
                                <img src="assets/img/icon/kaliteli-hizmet.svg" alt="image" style="height: 75px;margin-left: -15px;">

                                <!-- <i class="bx bx-briefcase-alt-2"></i> -->
                            </div>
                            <h3>
                                <a routerLink="/">Kaliteli Hizmet ve Esnek Paket Seçenekleri
                                </a>
                            </h3>
                            <!-- <span>10 Courses</span> -->
                        </li>
                        <li>
                            <div class="icon">
                                <img src="assets/img/icon/lezzet-deneyimi.svg" alt="image" style="height: 75px;margin-left: -15px;">

                                <!-- <i class="bx bx-layer"></i> -->
                            </div>
                            <h3>
                                <a routerLink="/">Lezzet deneyimi ve Müşteri Geri Bildirimleri
                                </a>
                            </h3>
                            <!-- <span>15 Courses</span> -->
                        </li>
                        <li>
                            <div class="icon">
                                <img src="assets/img/icon/beslenme-danismanliği.svg" alt="image" style="height: 75px;margin-left: -15px;">
                                kaliteli-hizmet.svg
                                <!-- <i class="bx bx-target-lock"></i> -->
                            </div>
                            <h3>
                                <a routerLink="/">Beslenme Danışmanlığı ve Destek Hizmetleri</a>
                            </h3>
                            <!-- <span>13 Courses</span> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="motivation-categories-shape">
        <img src="assets/img/icon/tadivo-zemin.png" alt="image">
    </div>
</div>