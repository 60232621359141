<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide *ngFor="let item of referanceModel">
            <div class="single-partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img img [src]="path+'/Referances/'+item.image" alt="Image"></a>
            </div>
        </ng-template>
    
    </owl-carousel-o>
</div>