<app-header-style-seven></app-header-style-seven>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li>Blog Details</li>
            </ul> -->
            <h2>{{getnewsModel.title}}</h2>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img img [src]="path+'/WebImages/Announcements/'+getnewsModel.image" alt="Image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <!-- <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Kategori</span>
                                    <a routerLink="/single-blog">Yemek</a>
                                </li> -->
                                <!-- <li>
                                    <i class='bx bx-group'></i>
                                    <span>Students Enrolled</span>
                                    <a routerLink="/single-blog">813,454</a>
                                </li> -->
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Yüklenme Tarihi</span>
                                    <a routerLink="/single-blog">{{getnewsModel.creationDate | date:'dd-MM-yyyy' :'TR'}}</a>
                                </li>
                            </ul>
                        </div>
                        <h3>{{getnewsModel.title}}</h3>
                        <p innerHTML="{{getnewsModel.detail}}"></p>
                       
                       
                    </div>
                    <!-- <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-purchase-tag'></i></span>
                            <a routerLink="/blog-style-1">Fashion</a>,
                            <a routerLink="/blog-style-1">Games</a>,
                            <a routerLink="/blog-style-1">Travel</a>
                        </div>
                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a>
                                </li>
                                <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a>
                                </li>
                                <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="article-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src="assets/img/user1.jpg" class="shadow-sm rounded-circle" alt="image">
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>Chris Orwig</h4>
                                        <span class="d-block">Photographer, Author, Teacher</span>
                                    </div>
                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor" class="d-inline-block">View Profile on
                                            Ednuv</a>
                                    </div>
                                </div>
                            </div>
                            <p>Chris Orwig is a celebrated photographer, author, and teacher who brings passion to
                                everything he does.</p>
                        </div>
                    </div>
                    <div class="ednuv-post-navigation">
                        <div class="prev-link-wrapper">
                            <div class="info-prev-link-wrapper">
                                <a routerLink="/single-blog">
                                    <span class="image-prev">
                                        <img src="assets/img/blog/blog2.jpg" alt="image">
                                        <span class="post-nav-title">Prev</span>
                                    </span>
                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title">Don't buy a tech gift until you read these rules</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">January 21, 2020</span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="next-link-wrapper">
                            <div class="info-next-link-wrapper">
                                <a routerLink="/single-blog">
                                    <span class="next-link-info-wrapper">
                                        <span class="next-title">The golden rule of buying a phone as a gift</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">January 21, 2020</span>
                                        </span>
                                    </span>
                                    <span class="image-next">
                                        <img src="assets/img/blog/blog3.jpg" alt="image">
                                        <span class="post-nav-title">Next</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>
                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>
                                        <div class="comment-metadata">
                                            <a routerLink="/single-blog">
                                                <time>April 24, 2019 at 10:59 am</time>
                                            </a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type specimen.</p>
                                    </div>
                                    <div class="reply">
                                        <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/single-blog">
                                                        <time>April 24, 2019 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and
                                                    scrambled it to make a type specimen.</p>
                                            </div>
                                            <div class="reply">
                                                <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                    <ol class="children">
                                        <li class="comment">
                                            <article class="comment-body">
                                                <footer class="comment-meta">
                                                    <div class="comment-author vcard">
                                                        <img src="assets/img/user3.jpg" class="avatar" alt="image">
                                                        <b class="fn">Sarah Taylor</b>
                                                        <span class="says">says:</span>
                                                    </div>
                                                    <div class="comment-metadata">
                                                        <a routerLink="/single-blog">
                                                            <time>April 24, 2019 at 10:59 am</time>
                                                        </a>
                                                    </div>
                                                </footer>
                                                <div class="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever
                                                        since the 1500s, when an unknown printer took a galley of type
                                                        and scrambled it to make a type specimen.</p>
                                                </div>
                                                <div class="reply">
                                                    <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                                </div>
                                            </article>
                                        </li>
                                    </ol>
                                </ol>
                            </li>
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user4.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>
                                        <div class="comment-metadata">
                                            <a routerLink="/single-blog">
                                                <time>April 24, 2019 at 10:59 am</time>
                                            </a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                            when an unknown printer took a galley of type and scrambled it to make a
                                            type specimen.</p>
                                    </div>
                                    <div class="reply">
                                        <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user5.jpg" class="avatar" alt="image">
                                                    <b class="fn">James Anderson</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/single-blog">
                                                        <time>April 24, 2019 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and
                                                    scrambled it to make a type specimen.</p>
                                            </div>
                                            <div class="reply">
                                                <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>
                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" placeholder="Your Name*" name="author"
                                        required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" placeholder="Your Email*" name="email"
                                        required="required">
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" placeholder="Website" name="url">
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..."
                                        rows="5" maxlength="65525" required="required"></textarea>
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent"
                                        id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this
                                        browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post Comment">
                                </p>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 10, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Making Peace With The Feast Or
                                        Famine Of Freelancing</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 21, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">I Used The Web For A Day On A 50
                                        MB Budget</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2019-06-30">June 30, 2019</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">How To Create A Responsive Popup
                                        Gallery?</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/blog-style-2">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/blog-style-2">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/blog-style-2">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/blog-style-2">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog-style-2">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Ednuv Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/blog-style-2">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Raque <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-style-2">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-style-2">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-style-2">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div>
                    <div class="widget widget_instagram">
                        <h3 class="widget-title">Instagram</h3>
                        <ul>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog1.jpg"
                                        alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog2.jpg"
                                        alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog3.jpg"
                                        alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog4.jpg"
                                        alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog5.jpg"
                                        alt="image"></a></li>
                            <li><a href="#" target="_blank" class="d-block"><img src="assets/img/blog/blog6.jpg"
                                        alt="image"></a></li>
                        </ul>
                    </div>
                    <div class="widget widget_contact">
                        <div class="text">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <span>Emergency</span>
                            <a href="tel:+098798768753">+0987-9876-8753</a>
                        </div>
                    </div>
                </aside>
            </div> -->
        </div>
    </div>
</div>