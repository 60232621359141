import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting.service';
import { SettingModel } from '../../models/socialmediaModel';
import { PagesService } from '../../services/pages.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  socialMediaModel: SettingModel
  pagesModel:any;

  twitterModel: SettingModel;
  instagramModel: SettingModel;
  youtubeModel: SettingModel;
  facebookModel: SettingModel;
  mailModel:SettingModel;
  phoneModel:SettingModel;
  adressModel:SettingModel;
  constructor(private settingService: SettingService,private pagesService:PagesService) { }

  ngOnInit(): void {
    this.pagesList();
    this.twitterList()
    this.instagramList();
    this.youtubeList();
    this.facebookList();
    this.mailList();
    this.phoneList();
    this.adressList();
  }

  pagesList(){
    this.pagesService.getList().subscribe(data => {
      this.pagesModel = data;
      // console.log("Pages, Model",this.pagesModel)
  })
  }
  twitterList() {
    this.settingService.getsocialMedia("twitter", 1).subscribe(data => {
      this.twitterModel = data;
    })
  }
  instagramList() {
    this.settingService.getsocialMedia("instagram", 1).subscribe(data => {
      this.instagramModel = data;
    })
  }
  youtubeList() {
    this.settingService.getsocialMedia("youtube", 1).subscribe(data => {
      this.youtubeModel = data;
    })
  }
  facebookList() {
    this.settingService.getsocialMedia("facebook", 1).subscribe(data => {
      this.facebookModel = data;
    })
  }
  mailList(){
    this.settingService.getcontact("mail", 1).subscribe(data => {
      this.mailModel = data;
      // console.log("Mail",this.mailModel)
    })
  }
  phoneList(){
    this.settingService.getcontact("telefon", 1).subscribe(data => {
      this.phoneModel = data;
    })
  }
  adressList(){
    this.settingService.getcontact("adres", 1).subscribe(data => {
      this.adressModel = data;
    })
  }
}
