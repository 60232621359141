<app-header-style-seven></app-header-style-seven>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="courses-2-columns-style-1.html">Courses</a></li>
                <li>Certified Graphic Design with Free Project Course</li>
            </ul> -->
            <h2>{{pageDetail.title}}</h2>
        </div>
    </div>
</div>

<div class="courses-details-area pt-100 pb-70">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="courses-title">
                        <h2>{{pageDetail.title}}</h2>
                        <p [innerHTML]="pageDetail.preContent"></p>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <div class="courses-details-image text-center">
                    <img src="{{path}}/WebImages/Pages/{{pageDetail.contentImage}}">
                </div>
                <div class="courses-details-desc">
                    <p [innerHTML]="pageDetail.content"></p>
                </div>
                
            </div>
            <div class="col-lg-4">
                <!-- <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Students:</span>
                            10
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Length:</span>
                            1 Weeks
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Effort:</span>
                            2–5 hours per week
                        </li>
                        <li>
                            <span><i class='bx bxs-institution'></i> Institution:</span>
                            <a href="#" target="_blank" class="d-inline-block">ABC</a>
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Subject:</span>
                            Design
                        </li>
                        <li>
                            <span><i class='bx bx-atom'></i> Quizzes:</span>
                            Yes
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Level:</span>
                            Introductory
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Language:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-text'></i> Video Subtitle:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Certificate:</span>
                            Yes
                        </li>
                    </ul>
                </div> -->
                <div class="courses-sidebar-syllabus">
                    <h3>En güzel anlarınızda büyük titizlikle yanınızdayız
                    </h3>
                    <!-- <h3>Hijyen ve lezzet içeren kusursuz bir hizmet.</h3> -->
                    <!-- <span class="chapter">Chapter 1</span>
                    <h4>Lessons</h4> -->
                    <div class="courses-list">
                        <ul>
                            <!-- <li>
                                Introduction
                            </li> -->
                            <li>
                                    <span class="number">1.</span> Kusursuz catering hizmeti.
                            </li>
                           
                            <li>
                                <span class="number">2.</span> Sağlığınız bizim için her şeyden önemli.

                            </li>
                            
                            <li>
                                <span class="number">3.</span> Hizmetimizin sınırı yok.

                            </li>
                            
                            <li>
                                <span class="number">4.</span> Kurumsal veya bireysel, nasıl isterseniz.

                            </li>
                            <li>
                                <span class="number">5.</span> Özel bir isteğiniz mi var?

                            </li>
                            <li>
                                <span class="number">6.</span> Ortama uygun hizmetler.

                            </li>
                           
                            <li>
                                <span class="number">7.</span> Sağlıklı beslenmenizi gerçekten anlıyoruz.

                            </li>
                            <li>
                                <span class="number">8.</span> Pratik olması bile yeterli bir sebep.

                            </li>
                            <li>
                                <span class="number">9.</span> En ufak detay bile planımıza dahil.


                            </li>
                            <li>
                                <span class="number">10.</span> Her şey kontrolümüz altında.

                            </li>
                           
                        </ul>
                    </div>
                    <!-- <span class="chapter">Chapter 2</span> -->
                    <!-- <h4>En iyi fiyat garantisini veriyoruz.
                    </h4> -->
                    <div class="col-lg-6" style="margin: auto;">
                        <div class="courses-price">
                            <a routerLink="/priceOffer" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Fiyat Teklifi Al</span><i class="bx bx-paper-plane icon-arrow after"></i></a>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</div>