<app-header-style-seven></app-header-style-seven>
<br>
<div class="section" style="background-color: #191919;">
<div class="container"  >

<div class="row align-items-center">
    <div class="col-lg-6 col-md-12" style="margin-top: 3%;">
        <div class="about-image">
            <img src="assets/img/about.jpg" class="shadow" alt="image">
            <!-- <img src="assets/img/about/about2.jpg" class="shadow" alt="image"> -->
        </div>
    </div>
    <div class="col-lg-6 col-md-12">
        <div class="about-content">
            <!-- <span class="sub-title">About Us</span> -->
            <h2 style="color: #fff;">Hakkımızda</h2>
            <p style="color: #fff;text-align: justify;">{{aboutModel.value}}</p>
        </div>
    </div>
</div>

</div>
</div>
<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/oven.jpg" class="shadow" alt="image">
                    <!-- <img src="assets/img/about/about2.jpg" class="shadow" alt="image"> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">About Us</span> -->
                    <h2>Tadivo Fırın</h2>
                    <!-- <h6>We can support student forum 24/7 for national and international students.</h6> -->
                    <p style="text-align: justify;">TADİVO geleneksel fırıncılık sanatını modern teknolojiyle birleştirerek, her bir ürünümüzde kalite ve lezzeti ön planda tutar. Usta fırıncılarımızın elinden çıkan ekmeklerimiz, özenle seçilmiş malzemelerle hazırlanan pastalarımız ve enfes tatlılarımızla damaklarınızı şenlendirmek için sabırsızlanıyoruz.</p>

                    <div class="col-lg-12 col-md-6 col-sm-6">
                        <div class="about-text">
                            <h3>Her Tabakta Sağlık, Her Lokmadan Mutluluk </h3>
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i> Özel İhtiyaca Yönelik Menüler</li>
                                <li><i class='bx bx-check'></i> Taze ve Kaliteli Malzemeler</li>
                                <li><i class='bx bx-check'></i> Sağlıklı Tarifler ve Beslenme Önerileri</li>
                                <li><i class='bx bx-check'></i> Kaliteli Hizmet ve Esnek Paket Seçenekleri</li>
                                <li><i class='bx bx-check'></i> Lezzet deneyimi ve Müşteri Geri Bildirimleri</li>
                                <li><i class='bx bx-check'></i> Beslenme Danışmanlığı ve Destek Hizmetleri</li>

                            </ul>
                        </div>
                    </div>
                 
                    <!-- <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>Education encompasses both the teaching and learning of knowledge, proper conduct, and
                            technical competency.</p>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>100,000 online courses</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful
                            communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Expert instruction</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful
                            communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Creating. Results.</li>
                            <li><i class='bx bx-check'></i> Expect more</li>
                            <li><i class='bx bx-check'></i> Good thinking</li>
                            <li><i class='bx bx-check'></i> In real we trust</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Lifetime access</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful
                            communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Stay real. Always.</li>
                            <li><i class='bx bx-check'></i> We have you covered</li>
                            <li><i class='bx bx-check'></i> We turn heads</li>
                            <li><i class='bx bx-check'></i> Your brand, promoted</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

<!-- <app-our-mission></app-our-mission> -->

<!-- <div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div> -->

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<br>



<!-- <div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div> -->

<!-- <div class="instructor-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div> -->

<!-- <div class="partner-area bg-color ptb-100" style="background-color: #fff;">
    <div class="container">
        <div class="section-title">
            <h2>Şirketimiz ve Ortaklarımız</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div> -->

<!-- <div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div> -->
<!-- <app-become-instructor-partner></app-become-instructor-partner> -->