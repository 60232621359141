import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '../../services/pages.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-courses-details-page',
  templateUrl: './courses-details-page.component.html',
  styleUrls: ['./courses-details-page.component.scss']
})
export class CoursesDetailsPageComponent implements OnInit {
  pageDetail:any;
  path = environment.serverUrl
  
  constructor(private http:HttpClient,private pagesService:PagesService,
    private activatedRoute: ActivatedRoute) { 
      this.activatedRoute.params.subscribe(param=>{
        this.getPage(param['id'])
        // console.log("page param gelen",param)
      }
      )
    }
  ngOnInit(): void {
  }

  getPage(id:string){
    this.pagesService.getid(id).subscribe(resp=>{
      this.pageDetail=resp;
      // console.log("aaa",resp)
    })
  }

}
