<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 style="color:#D7282F;">Neden Tadivo Catering'i Tercih Etmelisiniz?</h2>
            <p>Şirketinizin lezzetli anlarını özel kılacak bir catering deneyimi sunuyoruz! Kurumsal etkinlikleriniz, toplantılarınız veya özel organizasyonlarınız için profesyonel catering hizmetimizle yanınızdayız.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let data of pagesModel">
                <div class="services-box">
                    <div class="icon">
                        <!-- <i class="flaticon-analytics"></i> -->
                        <img routerLink="{{data.link}}" src="{{path}}/WebImages/Pages/{{data.bannerImage}}" style="height: 75px;margin-left: -15px;">


                    </div>
                    <a href="{{data.link}}">
                    <h3 routerLink="{{data.link}}">{{data.title}}</h3>
                </a>
                    <p>Şirketinizin lezzetli anlarını özel kılacak bir catering deneyimi sunuyoruz! </p>
                </div>
            </div>
        </div>
    </div>
    <div class="divider bg-e4feff"></div>
</section>