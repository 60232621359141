<app-header-style-seven></app-header-style-seven>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li>Courses</li>
            </ul> -->
            <h2>Tadivo Blog</h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <!-- <p>Showing 1 – 6 of 54</p> -->
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <!-- <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                    </form>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of newsModel">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/blog-details/{{item.id}}" class="d-block"><img img [src]="path+'/WebImages/Announcements/'+item.image" alt="Image">
                        </a>
                        <div class="courses-tag">
                            <!-- <a routerLink="/single-blog" class="d-block">Business</a> -->
                        </div>
                    </div>
                    <div class="courses-content">
                        
                        <h3><a routerLink="/blog-details/{{item.id}}" class="d-inline-block">{{item.title}}</a></h3>
                      
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <!-- <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li> -->
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> {{item.creationDate | date:'dd-MM-yyyy' :'TR'}}
                            </li>
                            <!-- <li class="courses-price">
                                Free
                            </li> -->
                        </ul>
                        
                    </div>
                </div>
            </div>
         
            <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">2</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">3</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">4</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">5</a>
                    <a routerLink="/courses-3-columns-style-1" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>