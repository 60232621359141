<div class="blog-slides">
    <owl-carousel-o [options]="blogSlides">
        <ng-template carouselSlide *ngFor="let item of newsModel">
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a routerLink="/blog-details/{{item.id}}" class="d-block">
                        <img img [src]="path+'/WebImages/Announcements/'+item.image" alt="Image">
                    </a>
                    <div class="tag">
                        <!-- <a routerLink="/">Learning</a> -->
                    </div>
                </div>
                <div class="post-content">
                    <ul class="post-meta">
                        <!-- <li class="post-author">
                            <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                            By: <a routerLink="/" class="d-inline-block">Steven Smith</a>
                        </li> -->
                        <li><a >{{item.creationDate | date:'dd-MM-yyyy' :'TR'}}</a></li>
                    </ul>
                    <h3><a routerLink="/blog-details/{{item.id}}" class="d-inline-block">{{item.title}}</a></h3>
                    <a routerLink="/blog-details/{{item.id}}" class="read-more-btn">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </ng-template>
   
    </owl-carousel-o>
</div>