import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { CategoryModel } from '../../models/categoryModel';
import { ActivatedRoute } from '@angular/router';
import { PagesService } from '../../services/pages.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  path = environment.serverUrl

  categoryModel:CategoryModel
  pagesModel:any;

  constructor(private router: ActivatedRoute,private categoryService:CategoryService,private pagesService:PagesService) { }

  ngOnInit(): void {
    this.categoryGeneralList();
    this.pagesList();
  }
  categoryGeneralList(){
    this.categoryService.getList().subscribe(data => {
      this.categoryModel = data;
      // console.log("Kategoriler",this.categoryModel)
  })
}
pagesList(){
  this.pagesService.getList().subscribe(data => {
    this.pagesModel = data;
    // console.log("Pages, Model",this.pagesModel)
})
}
}
