<app-header-style-seven></app-header-style-seven>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li>Contact</li>
            </ul> -->
            <h2>İletişim</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email</h3>
                    <p><a href="mailto:{{mailModel.value}}">{{mailModel.value}}</a></p>
                    <!-- <p><a href="mailto:ednuv@hello.com">ednuv@hello.com</a></p> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Adres</h3>
                    <p><a href="https://www.google.com.tr/maps/place/M%C3%9CSDAV/39.9342068,32.8644314,17z/data=!3m1!4b1!4m5!3m4!1s0x14d34e42e68a7fef:0xeff206856f992fc8!8m2!3d39.9342075!4d32.8666317?hl=tr" target="_blank">{{adresModel.value}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Telefon</h3>
                    <p><a href="tel:1234567890">{{phoneModel.value}}</a></p>
                    <!-- <p><a href="tel:2414524526">+241 452 4526</a></p> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Contact Us</span> -->
            <h2>Sorularınız, önerileriniz veya talepleriniz için bizimle iletişime geçebilirsiniz...</h2>
        </div>
        <div class="contact-form">
            <form id="contactForm" [formGroup]="form"  (ngSubmit)="submitForm()">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" name="firstName" formControlName="firstName" id="name" type="text"  class="form-control" placeholder="Adınız">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" name="lastName" formControlName="lastName" id="lastName"class="form-control"  placeholder="Soyadınız">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required id="email" name="email" formControlName="email" type="text"  class="form-control" placeholder="E-Postanız">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required name="phone" id="phone" formControlName="phone" class="form-control" placeholder="Telefonunuz">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required name="subject" formControlName="subject" id="name" class="form-control"  placeholder="Konunuz">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required name="message" id="message" formControlName="message" cols="30" rows="5" class="form-control" placeholder="Mesajınız..."></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" ><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Mesaj Gönder</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3059.46825854795!2d32.6144402!3d39.9309144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d33a30478f85d5%3A0xd211f4368a06812c!2sAyy%C4%B1ld%C4%B1z%2C%201673.%20Sk.%2C%2006796%20Etimesgut%2FAnkara!5e0!3m2!1str!2str!4v1704359270301!5m2!1str!2str"></iframe>
</div>
