<div class="testimonials-slides">
    <owl-carousel-o [options]="testimonialsSlides">
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>
                    Kare Catering ekibi gerçekten harikaydı ve birlikte çalışması çok zevkliydi. Yemek için birçok
                    seçeneğe sahipler ve harika önerilerde bulunuyorlar. Yemek, detaylar ve servis harikaydı. Kesinlikle
                    onları tekrar etkinlikler için kullanacağız. </p>
                <div class="info">
                    <!-- <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image"> -->
                    <h3>Sinan Bey</h3>
                    <span>Girişimci</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>
                    Kare Catering ekibi gerçekten harikaydı ve birlikte çalışması çok zevkliydi. Yemek için birçok seçeneğe sahipler ve harika önerilerde bulunuyorlar. Yemek, detaylar ve servis harikaydı. Kesinlikle onları tekrar etkinlikler için kullanacağız.                              </p>
                <div class="info">
                    <!-- <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image"> -->
                    <h3>Ahmet Bey</h3>
                    <span>Girişimci</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Kare Catering ekibi gerçekten harikaydı ve birlikte çalışması çok zevkliydi. Yemek için birçok seçeneğe sahipler ve harika önerilerde bulunuyorlar. Yemek, detaylar ve servis harikaydı. Kesinlikle onları tekrar etkinlikler için kullanacağız.</p>
                <div class="info">
                    <!-- <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image"> -->
                    <h3>Murat Bey</h3>
                    <span>Yazılım Uzmanı</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>
                    Kare Catering ekibi gerçekten harikaydı ve birlikte çalışması çok zevkliydi. Yemek için birçok seçeneğe sahipler ve harika önerilerde bulunuyorlar. Yemek, detaylar ve servis harikaydı. Kesinlikle onları tekrar etkinlikler için kullanacağız.                              </p>
                <div class="info">
                    <!-- <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image"> -->
                    <h3>Oğuzhan Bey</h3>
                    <span>Proje Yöneticisi</span>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>