import { Component, OnInit } from '@angular/core';
import { ContentsService } from '../../services/contents.service';
import { ContentsModel } from '../../models/contentsModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-courses-grid-style-one-columns-three-page',
  templateUrl: './courses-grid-style-one-columns-three-page.component.html',
  styleUrls: ['./courses-grid-style-one-columns-three-page.component.scss']
})
export class CoursesGridStyleOneColumnsThreePageComponent implements OnInit {

	path = environment.serverUrl;

	newsModel: ContentsModel

	constructor(private contentsService: ContentsService) { }

	ngOnInit(): void {
		this.getnewsList();
	}

	getnewsList() {
		this.contentsService.getnewsList().subscribe(newsdata => {
			this.newsModel = newsdata;
		})
	}


}
