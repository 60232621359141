import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting.service';
import { SettingModel } from '../../models/socialmediaModel';

@Component({
  selector: 'app-about-page-one',
  templateUrl: './about-page-one.component.html',
  styleUrls: ['./about-page-one.component.scss']
})
export class AboutPageOneComponent implements OnInit {

  aboutModel:SettingModel
  constructor(private settingService:SettingService) { }

  ngOnInit(): void {
    this.aboutList();
  }
  aboutList() {
    this.settingService.getcontact("hakkimizda", 1).subscribe(data => {
      this.aboutModel = data;
      // console.log("Hakkımızda",this.aboutModel)
    })
  }


  

}
