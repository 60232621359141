<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Blog</li>
            </ul>
            <h2>Blog Grid</h2>
        </div>
    </div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6"  *ngFor="let item of newsModel">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog1.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Learning</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <!-- <li class="post-author">
                                <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Steven Smith</a>
                            </li> -->
                            <li><a routerLink="/blog-style-1">{{item.creationDate  | date:'dd,MM,yyyy' :'TR'}}</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">{{item.title}}</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Devamını oku <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog-style-1" class="page-numbers">2</a>
                    <a routerLink="/blog-style-1" class="page-numbers">3</a>
                    <a routerLink="/blog-style-1" class="page-numbers">4</a>
                    <a routerLink="/blog-style-1" class="page-numbers">5</a>
                    <a routerLink="/blog-style-1" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>