import { Component, OnInit, HostListener } from '@angular/core';
import { FrontMenüService } from '../../services/frontMenü.service';

@Component({
    selector: 'app-header-style-seven',
    templateUrl: './header-style-seven.component.html',
    styleUrls: ['./header-style-seven.component.scss']
})
export class HeaderStyleSevenComponent implements OnInit {
    frontModel!: any;

    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private frontMenüService: FrontMenüService) {
        
     }

    ngOnInit(): void {
        this.frontist();
    }

    frontist() {
        this.frontMenüService.getList().subscribe(data => {
            this.frontModel = data;
            // console.log("Menüler",this.frontModel)
        })
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

}