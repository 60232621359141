<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" style="text-align: center;">
                <div class="single-footer-widget mb-30">
                    <h3>Bize Ulaşın </h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">{{adressModel?.value}}</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+{{phoneModel?.value}}">{{phoneModel?.value}}</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:{{mailModel?.value}}">{{mailModel?.value}}</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="{{facebookModel?.value}}" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="{{twitterModel?.value}}" class="d-block" target="_blank"><i class="fa-solid fa-x"></i></a></li>
                        <li><a href="{{instagramModel?.value}}" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <!-- <li><a href="{{youtubeModel?.value}}" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6" style="text-align: center;">
                <div class="single-footer-widget mb-30">
                    <h3>Kısayol
                    </h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Anasayfa</a></li>
                        <li><a routerLink="/about">Hakkımızda</a></li>
                        <li><a routerLink="/contact">Bize Ulaşın</a></li>
                        <!-- <li><a routerLink="/">Sertifikalarımız</a></li> -->
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a routerLink="/galley">Galeri</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6" style="text-align: center;">
                <div class="single-footer-widget mb-30">
                    <h3>Hizmetler</h3>
                    <ul class="useful-link">
                        <li *ngFor="let data of pagesModel"><a routerLink="{{data.link}}">{{data.title}}</a></li>
                      
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
            </div>
            <p>© Designed and Developed by <a href="https://vakifglobal.com/" target="_blank" ><img src="https://diyanet.se/assets/img/isvecPhotos/vakifGlobalLogo.png" style="width: 135px;" alt="image"></a></p>
        </div>
    </div>
</footer>
<ngx-scrolltop></ngx-scrolltop>
