import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GalleryModel } from '../../models/galleryModel';
import { Lightbox } from 'ngx-lightbox';
import { GalleryService } from '../../services/gallery.service';
import { CertificateService } from '../../services/certificate.service';

@Component({
  selector: 'app-instructors-page-three',
  templateUrl: './instructors-page-three.component.html',
  styleUrls: ['./instructors-page-three.component.scss']
})
export class InstructorsPageThreeComponent implements OnInit {
  path = environment.serverUrl;

  public _album = [];
 public certificateModel:any
  constructor(public _lightbox: Lightbox, private certificateService:CertificateService) {
     
  }

  ngOnInit(): void {
      this.certificateList();
  }

  certificateList(){
      this.certificateService.getList().subscribe(data => {
          this.certificateModel = data;
          for (let i = 1; i <= 9; i++) {
              const src = this.path+'/Certificates/' + this.certificateModel[i].image;
              const caption = this.certificateModel[i].order;
              const thumb = this.path+'/Certificates/' + this.certificateModel[i].image;
              const album = {
                  src: src,
                  caption: caption,
                  thumb: thumb
              };
              this._album.push(album);
          }
          // console.log("Resimler",this.galleryModel)
      })
   }
  open(index: number): void {
      // open lightbox
      this._lightbox.open(this._album, index);
  }
  close(): void {
      // close lightbox programmatically
      this._lightbox.close();
  }
}